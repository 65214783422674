/** @jsx jsx */
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { EliteData } from "@/data";
import { jsx } from "@emotion/react";
import { useMediaQuery } from 'react-responsive';
import {
  abAgency,
  pr79,
  aprovados,
  content,
} from "../assets/styles/AboutTwo.styles";

import Elite from "../assets/images/LP-teamsix/elite.png";

const AboutTwo = () => {
  const [counter, setCounter] = useState({
    startCounter: false,
  });
  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setCounter({ startCounter: true });
    }
  };
  const isMobile = useMediaQuery({
    query: '(max-width: 850px)'
  })

  return (
    <section css={abAgency}>
      <Container fluid>
        <Row className="title-content">
        {!isMobile &&
          <Col
          className="img-container px-0"
          data-aos="fade-right"
          lg={6}
          md={12}
          sm={12}
        >
          <img src={Elite} alt="Calendario prof no app para celular" />
        </Col>}
          <Col css={content} lg={6} md={12} sm={12}>
            <div>
              <h1>EFICIÊNCIA DE ELITE FOCADA EM CONCURSOS MILITARES</h1>
              <p>O Team Six possui experiência e know-how de aprovação. Nos últimos 10 anos, somos referência em metodologia de alta performance e em aprovação no RS, nos concursos militares e vestibulares internacionais.</p>
              <div css={aprovados}>
                <div>
                  <h3>
                    +
                    <VisibilitySensor
                      onChange={onVisibilityChange}
                      offset={{ top: 10 }}
                      delayedCall
                    >
                      <CountUp end={156} />
                    </VisibilitySensor>
                  </h3>
                  <p>aprovados</p>
                  <h2>EsPCEx</h2>
                </div>
                <div>
                  <h3>
                    +
                    <VisibilitySensor
                      onChange={onVisibilityChange}
                      offset={{ top: 10 }}
                      delayedCall
                    >
                      <CountUp end={80} />
                    </VisibilitySensor>
                  </h3>
                  <p>aprovados</p>
                  <h2>AFA</h2>
                </div>
              </div>
              <div css={aprovados} style={{ marginTop: 24 }}>
                <div>
                  <h3>
                    +
                    <VisibilitySensor
                      onChange={onVisibilityChange}
                      offset={{ top: 10 }}
                      delayedCall
                    >
                      <CountUp end={90} />
                    </VisibilitySensor>
                  </h3>
                  <p>aprovados</p>
                  <h2>EFOMM</h2>
                </div>
                <div>
                  <h3>
                    +
                    <VisibilitySensor
                      onChange={onVisibilityChange}
                      offset={{ top: 10 }}
                      delayedCall
                    >
                      <CountUp end={48} />
                    </VisibilitySensor>
                  </h3>
                  <p>aprovados</p>
                  <h2>IME/ITA</h2>
                </div>
              </div>
            </div>
          </Col>        
        </Row>
      </Container>
      {isMobile && 
      <Col
      className="img-container px-0"
      data-aos="fade-right"
      lg={6}
      md={12}
      sm={12}
    >
      <img src={Elite} alt="Calendario prof no app para celular" />
    </Col>}
    </section>
  );
};

export default AboutTwo;
